'use client'
import {useLocaleContext} from "@/contexts/LocaleContext";
import Section from "@/components/atoms/Section";
import Container from "@/components/atoms/Container";
import Card from "@/components/atoms/Card";
import TextBlock from "@/components/molecules/TextBlock";
import Accordion from "@/components/organisms/Accordion";

export const SectionThree = ({locale}) => {
    // const {locale} = useLocaleContext()
    return <Section id="korzysci" className="container text-center lg:text-left mt-10 lg:mt-20">
        <Container className="">
            <Card className="!bg-blue-1 py-4 lg:py-24 lg:px-16 xl:px-38 w-full">
                <div className="flex flex-col lg:flex-row">
                    <TextBlock texts={locale?.benefits}/>
                </div>
                <div className="flex flex-col lg:flex-row gap-4 lg:gap-8 pt-4 lg:pt-14 ">
                    <Accordion
                        accordion={locale?.benefits.accordion}
                        className="lg:w-8/12"
                    />
                </div>
            </Card>
        </Container>
    </Section>

}