"use client";
import Section from "@/components/atoms/Section";
import Container from "@/components/atoms/Container";
import Card from "@/components/atoms/Card";
import TextBlockContact from "@/components/organisms/TextBlockContact";
import MapGoogle from "@/components/organisms/Map";

export const SectionFive = ({ locale }) => {
  return (
    <Section
      id="kontakt"
      className="container text-center lg:text-left mt-10 lg:mt-20"
    >
      <Container className="">
        <Card className="!bg-blue-1 w-full flex flex-col lg:flex-row">
          <div className="w-full lg:w-1/3 xl:w-5/12 pt-4 lg:py-24 xl:py-28 lg:ps-16 lg:pe-4 xl:ps-38">
            <TextBlockContact texts={locale?.contact} />
          </div>
          <div className="w-full lg:w-2/3 h-96 lg:h-auto lg:items-stretch rounded-2xl overflow-hidden relative">
            <MapGoogle params={locale?.contact} />
            <Card className="!bg-blue-100 z-20 w-23/ xl:w-1/3 absolute top-32 xl:h-28 right-2 lg:right-4 xl:right-36">
              <TextBlockContact
                button={locale?.contact.cta}
                address={locale?.contact}
                className="bg-blue-100 bg-blue-2xl lg:p-4 xl:p-8 xl:py-10 rounded-2xl relative -top-4 right-0"
              />
            </Card>
          </div>
        </Card>
      </Container>
    </Section>
  );
};
