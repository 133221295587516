'use client'
import Li from "@/components/atoms/Li";
import Link from "next/link";
import Image from "next/image";
import { useAuthContext } from "@/contexts/AuthContext";

export const ListOfNavi = ({ isOpenChange, isOpen, locale }) => {
    const { isLoggedIn, onLogout } = useAuthContext()
    // const [isOpen, setIsOpen] = useState(false);

    // const isOpenChange = () => {
    //     setIsOpen(!isOpen)
    // };

    return <ul
        className={`${!isOpen ? "hidden" : ""
            } z-50 container absolute lg:relative top-16 lg:top-0 flex-row me-6 lg:me-0 text-right lg:text-center bg-white w-full lg:flex justify-around item-center items-center lg:w-2/6 text-blue-100 text-xl lg:text-sm`}
    >
        {isLoggedIn && (<Li name="Dashboard" link="/dashboard" className="text-red" />)}
        {locale?.page?.navigations.navs.length > 0 && locale.page.navigations.navs.map((nav, nid) => (
            <Li isOpenChangeHandler={() => isOpenChange} key={nid} name={nav.name} link={nav.url}
                className={nav.name === 'Kontakt' ? 'mb-10 lg:mb-0' : ''} />
        ))}
        {isLoggedIn && (<li className="cursor-pointer" onClick={onLogout}>Logout</li>)}
        <li className="hidden lg:block">
            <Link href={`tel:${locale?.contact.phone}`}>
                <Image
                    src={locale?.contact.icoPhone.src}
                    width={locale?.contact.icoPhone.w}
                    height={locale?.contact.icoPhone.h}
                    alt={locale?.contact.icoPhone.h}
                />
            </Link>
        </li>
    </ul>
}