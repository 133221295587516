'use client'
import Section from "@/components/atoms/Section";
import Container from "@/components/atoms/Container";
import Link from "next/link";
import Button from "@/components/atoms/ui/Button";
import LoginPanel from "@/components/molecules/LoginPanel";

export const SectionOne = ({locale}) => {
    const onClick = () => {
        console.log('onclicck')
    }
    return <Section className={`w-full main w-100 bg-blue-100 text-white`}>
        <Container className="flex-col lg:flex-row items-center">
            <div
                className="relative z-20 lg:px-8 py-14 lg:py-12 text-center lg:text-left lg:w-1/3 lg:ps-5 lg:pe-5 xl:ps-20">
                <h1 className="text-5xl leading-tight tracking-wide m-auto lg:ms-0 md:w-4/6">
                    {locale?.kvHeader.title}
                </h1>
                <h4 className="text-xs leading-normal text-blue-8 lg:text-blue-10 pt-6">
                    {locale?.kvHeader.description}
                </h4>
                <Link href={locale?.kvHeader.cta.url}>
                    <Button
                        className="bg-blue-50 m-auto mt-5 lg:m-0 lg:mt-5 lg:align-left"
                        type="button"
                        onClick={onClick}
                    >
                        {locale?.kvHeader.cta.name}
                    </Button>
                </Link>
            </div>
            <div style={{backgroundImage: `url(${locale?.kvHeader.backgroundImage})`}}
                 className={`absolute lg:relative z-10 w-full lg:w-2/3 py-8 md:py-24 bg-cover bg-no-repeat bg-start lg:bg-center flex md:justify-end items-center ${!locale?.login.visible ? 'h-log' : ''}`}>
                {locale?.login.visible && <LoginPanel/>}
            </div>
        </Container>
    </Section>

}