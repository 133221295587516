import z from "zod";
import { Mutation } from "@/api/graphqlApi";
import { verifyLoginTokens } from "@/app/lib/actions";
import { LOGIN } from "@/graphql/Login.graphql";

const schema = z.object({
  email: z
    .string({
      required_error: "required",
      invalid_type_error: "Invalid Email",
    })
    .email(),
  password: z.string(),
});

const validateCredetnials = (prevState, formData) => {
  const validatedFields = schema.safeParse({
    email: formData.get("login"),
    password: formData.get("password"),
  });

  // Return early if the form data is invalid
  if (!validatedFields.success) {
    return {
      errors: validatedFields.error.flatten().fieldErrors,
    };
  }

  return {
    message: "OK",
  };
};

export const Login = (prevState, formData) => {
  const { errors, message } = validateCredetnials(prevState, formData);
  
  if (errors) {
    return errors;
  }

  const VARS = {
    email: formData.get("login"),
    password: formData.get("password"),
  };

  if (message === "OK") {
    return Mutation(LOGIN, VARS).then((res) => {
      if (res.data.login.accessToken === null) return { authenticated: false };

      return verifyLoginTokens(res.data.login).then((res) => {
        return { authenticated: true, ...res };
      });
    });
  }
};
