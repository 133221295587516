'use client'
import Container from "@/components/atoms/Container";
import TextBlock from "@/components/molecules/TextBlock";
import Card from "@/components/atoms/Card";
import Image from "next/image";
import Section from "@/components/atoms/Section";
import {useLocaleContext} from "@/contexts/LocaleContext";

export const SectionTwo = ({locale}) => {
    // const {locale} = useLocaleContext()

    return <Section id="oferta" className="text-center lg:text-left">
        <Container className="w-full flex-col lg:px-20 xl:px-40 pt-26">
            <div className="flex-row">
                <TextBlock texts={locale?.offers.header} className="w-full"/>
            </div>
            <div className="flex-col lg:flex-row flex pt-4">
                <div className="w-full lg:w-2/3 grid gap-4 grid-cols-2 py-10 lg:py-22">
                    {locale?.offers.offers.length > 0 && locale.offers.offers.map(offer => (
                        <div key={offer.id} className="w-full grid items-stretch lg:py-10">
                            <TextBlock texts={offer} className=""/>
                        </div>
                    ))}
                </div>
                <div className="lg:w-1/3">
                    <Card className="w-full p-0 pt-8 lg:pt-22">
                        <Image src={locale?.questions.image.src} width={locale?.questions.image.w}
                               height={locale?.questions.image.h} alt={locale?.questions.image.alt}
                               className="w-full rounded-t-2xl relative z-0 h-auto"/>
                        <TextBlock texts={locale?.questions}
                                   className="p-6 py-12 md:py-16 lg:p-12 rounded-2xl relative -top-4 bg-blue-100"/>
                    </Card>
                </div>
            </div>
        </Container>
    </Section>
}