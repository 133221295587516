import Card from "../atoms/Card";

const Panel = ({ title, children, isActive, onShow }) => {
  return (
    <Card onShow={onShow}
      className={`group my-2 py-4 px-8 xl:px-20 2xl:px-28 transition-all duration-500 flex cursor-pointer ${
        isActive ? "bg-blue-100" : "hover:bg-blue-50 hover:transition-all"
      }`}
    >
      <div className="w-11/12 lg:w-5/6">
        <div
          className={`${
            isActive ? "text-white text-2xl" : "transition-all group-hover:transition-all text-blue-100 group-hover:text-white"
          } text-2xl py-4`}
        >
          {title}
        </div>
        {isActive ? (
          <p className={`${isActive ? "text-blue-10" : ""} py-5 leading-5`}>{children}</p>
        ) : (
          ""
        )}
      </div>
      <div className="w-1/12 lg:w-1/6 text-xl">
          <span className={`lg:ps-8 pt-4 block ${isActive ? 'text-white' : 'text-blue-100'} group-hover:text-white`}>{isActive ? "-" : "+"}</span>
      </div>
    </Card>
  );
};
export default Panel;
