const Texts = {
    page: {
        url: "/",
        title: "Skyforyou – to kompleksowa obsługa podróży służbowych.",
        description:
            "W tym optymalizacja kosztów podróży(bilety lotnicze, hotele, wynajem aut, ubezpieczenia…), obsługa 24/7, elastyczna forma rozliczeń, programy partnerskie i wiele innych. ",
        keywords: "",
        logo: {
            alt: "Logo SkyForYou",
            w: 140,
            h: 22,
            src: "/media/logo-skyforyou.png",
        },
    },
    kvHeader: {
        title: "Business Travel Management",
        description:
            "Jesteśmy ekspertami w zakresie \n kompleksowej obsługi podróży służbowych.",
        backgroundImage: '/media/group90@2.png',
        cta: {
            name: "Dowiedz się więcej",
            url: "/#oferta",
        },
    },
    login: {
        suptitle: "Jesteś już klientem",
        title: "Zaloguj się",
        placeholders: {
            login: "login",
            password: "hasło",
        },
        cta: {
            name: "Zaloguj się",
            url: "/#",
        },
    },
    offers: {
        header: {
            suptitle: "OFERTA",
            title: "Zadbamy o każdy \n szczegół Twojej podróży",
            description:
                "Zadbamy o to, aby jak najmniejszym kosztem, mogli Państwo realizować \n swoje cele biznesowe zarówno w Polsce jak i na całym świecie.",
        },
        offers: [
            {
                id: "id1",
                ico: true,
                image: {
                    alt: "",
                    w: 43,
                    h: 30,
                    src: "/media/airplane-ico@2.png",
                },
                suptitle: "",
                title: "bilety lotnicze",
                description: "Rezerwacja, doradztwo \n i sprzedaż biletów lotniczych.",
            },
            {
                id: "id2",
                ico: true,
                image: {
                    alt: "",
                    w: 46,
                    h: 30,
                    src: "/media/hotel-ico@2.png",
                },
                suptitle: "",
                title: "hotele",
                description: "Najlepsze ceny hoteli sieciowych \n i mniejszych obiektów.",
            },
            {
                id: "id3",
                ico: true,
                image: {
                    alt: "",
                    w: 42,
                    h: 30,
                    src: "/media/car-ico@2.png",
                },
                suptitle: "",
                title: "samochody",
                description: "Rezerwacja samochodów osobowych \n i organizacja transportu autokarowego",
            },
            {
                id: "id4",
                ico: true,
                image: {
                    alt: "",
                    w: 41,
                    h: 30,
                    src: "/media/train-ico@2.png",
                },
                suptitle: "",
                title: "bilety kolejowe",
                description: "Zamawianie biletów kolejowych \n na ternie Polski i całego świata.",
            },
        ],
    },
    questions: {
        suptitle: "Masz pytania",
        title: "Chcesz poznać szczegółową ofertę?",
        image: {
            alt: "",
            w: 256,
            h: 30,
            src: "/media/offers-bg@2.png",
        },
        cta: {
            name: "Napisz do nas",
            url: "/#kontakt",
        },
    },
    benefits: {
        suptitle: "KORZYŚCI",
        title:
            "Jesteśmy doradcami w organizacji i obsłudze \n każdego z elementów podróży służbowych",
        description:
            "Dzięki współpracy z nami zyskują Państwo nie tylko odpowiedź na zapytanie, ale przede wszystkim wiedzę na temat optymalizacji kosztów podroży.",
        img: {
            alt: "",
            w: 448,
            h: 518,
            src: "/media/foto@2.png",
        },
        accordion: [
            {
                id: "a1",
                title: "Optymalizacja kosztów podróży",
                description:
                    "Jesteśmy w stanie zaoszczędzić Państwa firmie nawet do 30% kosztów podróży służbowych. Nie pobieramy opłat abonamentowych, dlatego sprawdzając nas, mogą Państwo tylko zyskać",
            },
            {
                id: "a2",
                title: "Dostępność dla klientów 24h/7",
                description:
                    "Dla naszych stałych klientów uruchomiliśmy usługę telefonu alarmowego, dzięki czemu w każdej sytuacji jesteśmy w stanie pomóc naszym klientom. Z nami nigdy nie zostaną Państwa sami podczas podróży.",
            },
            {
                id: "a3",
                title: "Elastyczna forma rozliczeń",
                description:
                    "Rozliczamy się w systemie opartym na fakturach przelewowych z odroczonym terminem płatności bez konieczności uiszczenia opłaty w momencie składania zlecenia. Akceptujemy większość kart kredytowych.",
            },
            {
                id: "a4",
                title: "Programy lojalnościowe",
                description:
                    "Obsługujemy wszystkie dostępne na rynku programy lojalnościowe, prowadzone przez linie lotnicze. Dzięki temu nie tylko firmy, ale również pasażerowie mogą liczyć na dodatkowe benefity płynące ze współpracy z nami.",
            },
        ],
    },
    about: {
        header: {
            suptitle: "O NAS",
            title: "Świadczymy usługi zarówno dużym korporacjom, \n jak i średnim czy małym przedsiębiorstwom",
            description:
                "Każdego klienta traktujemy indywidualnie, dostosowując się do jego potrzeb \n" +
                "i oczekiwań, a także do profilu prowadzonej działalności.",
        },
        abouts: [
            {
                id: "id1",
                image: {
                    alt: "",
                    w: 256,
                    h: 30,
                    src: "/media/podroze.png",
                },
                suptitle: "",
                title: "Podróże to nasza pasja",
                description: "Na bieżąco śledzimy najnowsze rozwiązania, nowe połączenia, najlepsze oferty, aby móc dzielić się tą wiedzą z naszymi klientami.",
            },
            {
                id: "id2",
                image: {
                    alt: "",
                    w: 256,
                    h: 30,
                    src: "/media/oszczedzamy.png",
                },
                suptitle: "",
                title: "Oszczędzamy czas i pieniądze",
                description: "Jesteśmy doradcami w optymalizacji kosztów każdego z elementów podróży służbowych Państwa pracowników.",
            },
            {
                id: "id3",
                image: {
                    alt: "",
                    w: 256,
                    h: 30,
                    src: "/media/sprawdzeni.png",
                },
                suptitle: "",
                title: "Sprawdzony partner w biznesie",
                description: "Obsługujemy ponad 100 firm z różnych segmentów rynku, odpowiadając codziennie na ich potrzeby i oczekiwania.",
            },
            {
                id: "id4",
                image: {
                    alt: "",
                    w: 256,
                    h: 30,
                    src: "/media/doswiadczenie.png",
                },
                suptitle: "",
                title: "Doświadczenie i profesionalizm",
                description: "Nasi pracownicy, to bardzo doświadczona kadra ekspertów z wieloletnim doświadczeniem i wysoką kulturą osobistą.",
            },
        ],
    },
    contact: {
        suptitle: "KONTAKT",
        title:
            "Sky is not \n our limit",
        description:
            "Zaczynamy dla Państwa pracować od pierwszego telefonu lub maila.",
        email: 'podroze@skyforyou.pl',
        phone: '+48 22 215 90 89',
        companyName: "SkyForYou",
        companyAddress: "ul. Siedmiogrodzka 1\n lok. 700\n 01-204 Warszawa",
        location: {lat: 52.288110, lng: 20.862151},
        cta: {
            name: "Wyznacz trasę",
            url: "",
        },
    },
};

export default Texts;
