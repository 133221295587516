import Link from 'next/link'
import Image from 'next/image'
 
const Logo = ({className, img}) => {
    const classes = `logo my-auto ${className}`;
    return (
        <Link href="/" className={classes}>
            <Image src={img?.src} width={img?.w} height={img?.h} alt={img?.alt || 'img place'}/>
        </Link>
    );
};

export default Logo;
