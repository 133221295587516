"use client";
import { useState } from "react";
import Logo from "@/components/atoms/Logo";
import NavMobile from "@/components/molecules/NavMobile";
import Container from "@/components/atoms/Container";
import { ListOfNavi } from "@/components/molecules/navi/ListOfNavi";

const MainNavigation = ({ locale }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isOpenChange = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav
      className={`main-nav mx-auto bg-white lg:h-24 lg:top-0 lg:left-0 lg:right-0 z-50`}
    >
      <Container className="px-0 py-6 lg:py-0 !flex-row justify-between items-center">
        <Logo className="ms-4 md:ms-20" img={locale?.page?.logo} />
        <ListOfNavi isOpenChange={() => isOpenChange} locale={locale} />
        <NavMobile isOpen={isOpen} isOpenChangeHandler={isOpenChange} />
      </Container>
    </nav>
  );
};
export default MainNavigation;
