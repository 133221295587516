const NavMobile = ({isOpenChangeHandler, isOpen}) => {
  return (
    <nav className="lg:hidden w-1/12 flex navbar navbar-dark">
      <div className="container flex justify-center">
        <button
          onClick={isOpenChangeHandler}
          className="navbar-toggler"
          aria-expanded={isOpen}
        >
          <span className="navbar-toggler-icons"></span>
        </button>
      </div>
    </nav>
  );
};
export default NavMobile;
