"use client";
import Image from "next/image";
import Link from "next/link";
import Button from "../atoms/ui/Button";

const TextBlockContact = ({className, texts, address, button, onClick}) => {
    const classes = `p-4 lg:p-0  ${className} `;
    return (
        <div className={classes}>
            {texts && texts.suptitle && (
                <div className="text-blue-50 text-sm pb-4">
                    {texts.suptitle}
                </div>
            )}
            {texts && texts.title &&
                <div className="text-blue-100 pb-4 text-4xl leading-10">
                    {texts.title}
                </div>}
            <p className={`${classes.includes('bg-blue-2xl') ? 'text-blue-10 lg:w-full' : 'lg:w-2/3'} text-sm`}>{
                (texts ? texts.description : address.companyName + '\n\n' + address.companyAddress)
            }</p>
            {texts && <p className="my-4">
                <Link className="text-sm text-blue-100 underline"
                      href={`mailto:${texts.email}`}>{texts.email}</Link></p>}
            <p>
                {texts ? <Link className="text-sm text-blue-100" href={`tel:${texts.phone}`}>{texts.phone}</Link> :
                    <Link className="pt-4" target={button.target} href={button.url}>
                        <Button
                            className="bg-blue-50 px-12 text-sm m-auto lg:ms-0 mt-5"
                            type="button"
                            onClick={onClick}
                        >
                            {button.name}
                        </Button>
                    </Link>
                }
            </p>
        </div>
    );
};
export default TextBlockContact;
