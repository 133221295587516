'use client'
import {useLocaleContext} from "@/contexts/LocaleContext";
import Section from "@/components/atoms/Section";
import Container from "@/components/atoms/Container";
import TextBlock from "@/components/molecules/TextBlock";
import Card from "@/components/atoms/Card";
import Image from "next/image";

export const SectionFour = ({locale}) => {
    // const {locale} = useLocaleContext()
    return <Section id="o_nas" className="container text-center lg:text-left">
        <Container className="w-full flex-col px-6 md:px-20 xl:px-40 pt-8 lg:pt-16 xl:pt-26">
            <div className="flex-row overflow-hidden">
                <TextBlock texts={locale?.about.header} className=""/>
            </div>
            {/*<div className="pt-24 grid relative gap-4 xl:gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">*/}
            <div className="pt-24 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 relative gap-4 xl:gap-10">
                {locale?.about.abouts.map(el => (
                    <div key={el.id} className="md:flex items-stretch w-full">
                        <Card className="!bg-blue-100 z-20">
                            <Image src={el.image.src} width={el.image.w} height={el.image.h} alt={el.image.alt}
                                   className="w-full rounded-t-2xl relative z-0 h-auto"/>
                            <TextBlock texts={el}
                                       className="bg-blue-100 bg-blue-2xl lg:p-3 lg:py-8 2xl:p-8 xl:py-10 rounded-t-2xl relative -top-4"/>
                        </Card>
                    </div>
                ))}
            </div>
        </Container>
    </Section>

}