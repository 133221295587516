"use client";
import {useState} from "react";
import Panel from "../molecules/Panel";
import Card from "@/components/atoms/Card";
import Image from "next/image";

const Accordion = ({accordion, className, activeIdx, setActiveIdx}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    return (<>
            <div className={`${className} px-4 lg:px-0`}>
                {accordion.length > 0 &&
                    accordion.map((acc, index) => (
                        <Panel
                            key={acc.id}
                            title={acc.title}
                            isActive={activeIndex === index}
                            onShow={() => setActiveIndex(index)}
                        >
                            {acc.description}
                        </Panel>
                    ))}
            </div>
            <div className="w-1/2 mx-auto lg:w-5/12">
                <Card className="">
                    <Image
                        src={accordion[activeIndex].img.src}
                        width={accordion[activeIndex].img.w}
                        height={accordion[activeIndex].img.h}
                        alt={accordion[activeIndex].img.alt}
                        className="m-auto lg:m-0 pt-2"
                    />
                </Card>
            </div>
        </>
    );
};
export default Accordion;
