import Link from "next/link";
import { useParams } from "next/navigation";
import { useEffect, useState } from "react";

const Li = ({className, isOpenChangeHandler, link, name}) => {
  const [homeSection, setHomeSection] = useState("/");
  const classes = `${
    className ? className : ""
  } px-6 lg:px-0 py-8 bg-white border-t lg:border-none lg:hover:border-b-2 lg:hover:border-2`;
  const params = useParams();
  useEffect(() => {
    setHomeSection(window.location.hash);
  }, [params]);
  
  return (
    <li className={classes}>
      <Link onClick={isOpenChangeHandler}
        href={`${link}`}
        className={`link ${"/" + homeSection === link ? "active" : ""}`}
      >
        {name}
      </Link>
    </li>
  );
};
export default Li;
