"use client";
import Texts from "@/lib/texts";
import Button from "../atoms/ui/Button";
import Card from "../atoms/Card";
import { useEffect, useState } from "react";
import { useAuthContext } from "@/contexts/AuthContext";
import { useFormStatus, useFormState } from "react-dom";
import { Login } from "@/api/login";
import Link from "next/link";

const LoginPanel = () => {
  const { isLoggedIn, onLogin } = useAuthContext();
  const { pending } = useFormStatus();
  const [state, formAction] = useFormState(Login, undefined);
  const [loginMessage, setLoginMessage] = useState(null);
  useEffect(() => {
    console.log(state)
    if (state != undefined) {
      if (state.email) {
        setLoginMessage(state.email[0]);
      }

      if (state.authenticated) {
        onLogin(state);
      }
      if (!state.authenticated) {
        setLoginMessage("Invalid credentials.");
      }
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Card className="w-11/12 m-auto lg:w-7/12 flex lg:me-32 xl:w-6/12 2xl:w-5/12 flex-col items-center p-12 pb-6">
      <p className="text-blue-50 text-18">{Texts.login.suptitle}</p>
      {!isLoggedIn ? (
        <>
          <p className="text-blue-100 text-2xl">{Texts.login.title}</p>
          <form className="p-4 w-full" action={formAction}>
            <input
              className="w-full px-5 py-3 rounded-xl border border-blue-8 text-dark text-sm hover:border-blue-100 focus:border:blue-100"
              name="login"
              type="email"
              placeholder={Texts.login.placeholders.login}
            />
            <input
              className="mt-4 w-full px-5 py-3 rounded-xl border border-blue-8 text-dark text-sm hover:border-blue-100 focus:border:blue-100"
              name="password"
              type="password"
              placeholder={Texts.login.placeholders.password}
            />
            <div className="text-red text-center my-2">
              {loginMessage && <p>{loginMessage}</p>}
            </div>
            <Button
              className="mx-auto mt-4 px-10"
              type="submit"
              disabled={pending}
            >
              {Texts.login.cta.name}
            </Button>
            <Link href="/reset-password" className="text-11 text-center block mt-2 text-blue-10 hover:underline">Nie pamiętam hasła</Link>
          </form>
        </>
      ) : (
        <p className="text-blue-100 text-2xl pb-36">zalogowanym</p>
      )}
    </Card>
  );
};

export default LoginPanel;
