"use client";
import Image from "next/image";
import Link from "next/link";
import Button from "../atoms/ui/Button";

const TextBlock = ({className, texts}) => {
    const classes = `p-0 ${className} `;
    const image = texts.image;
    return (
        <div className={classes}>
            {texts.ico && (
                <Image
                    src={image.src}
                    width={image.w}
                    height={image.h}
                    alt={image.alt}
                    className="m-auto lg:m-0 pb-5"
                />
            )}
            {texts.suptitle && (
                <div
                    className={
                        !texts.icon && !texts.cta
                            ? "text-blue-50 text-lg pb-4"
                            : "text-18 text-blue-50"
                    }
                >
                    {texts.suptitle}
                </div>
            )}
            {texts.title &&
                <div
                    className={`${
                        classes.includes('bg-blue') ?
                            classes.includes('bg-blue-2xl') ? 'text-white pb-4 pt-2 text-2xl' : 'text-white pb-4 pt-2 text-4xl leading-10'
                            : texts.ico ? "text-32 text-blue-100 pb-2" : "text-4xl text-blue-100 pb-6 leading-10"} `
                    }
                >
                    {texts.title}
                </div>}
            <p className={`${classes.includes('bg-blue-2xl') ? 'text-blue-8 lg:w-full' : 'lg:w-2/3'} text-lg`}>{texts.description}</p>
            {texts.cta && (
                <Link href={/^\S+@\S+\.\S+$/.test(texts.cta.url) ? `mailto:${texts.cta.url}` : texts.cta.url}>
                    <Button
                        className="bg-blue-50 mt-5 px-12 text-sm m-auto lg:ms-0"
                        type="button"
                    >
                        {texts.cta.name}
                    </Button>
                </Link>
            )}
        </div>
    );
};
export default TextBlock;
